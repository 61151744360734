<template>
	<div v-if="page.elementData != null" class="textbox-element">
		<div class="title-container">
			<div class="container">
				<h1 class="title">Text-Element</h1>
				<h3 class="subtitle">Freien Text oder Wert eingeben</h3>
			</div>
			<button @click="$emit('delete')" class="delete-button">
				<img src="../../assets/icons/delete.svg" />
			</button>
		</div>
		<hr class="title-content" />
		<input
			v-model="page.elementData.data"
			@input="modifyElementData(page.elementData.id, page.elementData.data)"
			class="textbox-text"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
	name: 'TextboxElement',
	props: {
		project: Object,
		survey: Object,
		page: Object
	},
	methods: {
		...mapActions(['changeElementData']),
		modifyElementData: debounce(function (elementID, data) {
			this.changeElementData({ elementID, data });
		}, 400)
	}
};
</script>

<style lang="scss" scoped>
.textbox-element {
	padding: 20px;

	background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
	border: 1px solid $unnamed-color-999999;
	border-radius: 4px;

	margin-bottom: 40px;

	.delete-button {
		height: 48px;
		width: 48px;

		background: #321308 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		cursor: pointer;
		color: #fc6027;

		img {
			margin-top: 4px;
		}
	}

	.title-container {
		@include flex-between-center;

		.container {
			.title {
				@include bold-text-uppercase-22;
				color: $unnamed-color-999999;
			}

			.subtitle {
				@include normal-text-15;
				color: $unnamed-color-999999;
			}
		}
	}

	.title-content {
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.textbox-text {
		width: 100%;
		height: 48px;

		margin-right: 10px;
		padding: 0 16px;

		border: 1px solid $unnamed-color-999999;
		background: #262626 0% 0% no-repeat padding-box;
		border-radius: 4px;

		@include normal-text-22;
		color: $unnamed-color-ffffff;
	}

	.new-textbox {
		height: 48px;
		width: 226px;

		margin-top: 20px;
		padding-right: 20px;

		position: relative;
		text-align: right;

		background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		color: $unnamed-color-000000;
		cursor: pointer;

		span {
			@include bold-text-uppercase-22;
			color: inherit;
		}

		img {
			width: 24px;
			height: 24px;

			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background: #333333 0% 0% no-repeat padding-box;
			border: 2px solid black;
			border-radius: 4px;

			color: white;
		}
	}
}
</style>
